<template>
  <div class="container">
    <h1 class="mb-4">> SETTINGS :</h1>
    <div class="mb-4">
      <label>Do you have tickets for another night?</label>
      <pnw-button
        variant="outline-primary"
        class="w-100"
        size="lg"
        @click="addNewProfile"
      >
        <img src="/assets/images/icons/add.svg" alt="add" />
        ADD NEW PROFILE
      </pnw-button>
    </div>
    <div class="mb-4">
      <label>Switch between your different profiles:</label>
      <pnw-button
        variant="outline-primary"
        class="w-100"
        size="lg"
        @click="switchProfile"
      >
        <img src="/assets/images/icons/refresh.svg" alt="switch profile" />
        SWITCH PROFILE
      </pnw-button>
    </div>
    <div class="mb-4">
      <label>Logged in as {{ currentUserEmail }}</label>
      <pnw-button variant="primary" class="w-100" size="lg" @click="logout">
        <img src="/assets/images/icons/exit.svg" alt="log out" />
        LOG OUT
      </pnw-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PnwButton from "../components/PnwButton.vue";
import auth from "../services/AuthService";
export default {
  components: { PnwButton },
  name: "Settings",
  computed: {
    ...mapGetters({
      user: "currentUser",
    }),
    currentUserEmail() {
      return this.user?.email || "";
    },
  },
  mounted() {},
  methods: {
    logout() {
      auth.logout();
      this.$router.push({ name: this.$routerItems.LANDING });
    },
    addNewProfile() {
      this.$router.push({ name: this.$routerItems.TICKET_CODE });
    },
    switchProfile() {
      this.$router.push({ name: this.$routerItems.SELECT_PROFILE });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 600px;
}
</style>
